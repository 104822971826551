<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v">
        <div class="pub-tt m15">
          <h5 class="bt">
            <img
              src="../assets/images/equip_01.png"
              class="ico"
              alt=""
            />告警监控服务
          </h5>
          <div class="dix"></div>
        </div>
        <div class="p025 top-bar">
          <el-form ref="form" class="top-form" :model="form" label-width="90px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="设备">
                  <el-select
                    v-model="form.equip"
                    placeholder="选择设备"
                    @change="getAllMeterList"
                  >
                    <el-option
                      v-for="item in equiments"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    />
                    <el-option label="未选择" value="-1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="表计">
                  <el-select
                    v-model="form.biaoji"
                    placeholder="选择表计"
                    @change="getPointList"
                    popper-class="meterSelect"
                  >
                    <el-option
                      v-for="item in meters"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    />
                    <el-option label="未选择" value="-1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="点位名称">
                  <el-select
                    v-model="form.point"
                    placeholder="选择点位"
                    popper-class="pointSelect"
                  >
                    <el-option
                      v-for="item in points"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    />
                    <el-option label="未选择" value="-1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="内容描述">
                  <el-input v-model="form.desc"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="告警级别">
                  <el-select v-model="form.level" placeholder="">
                    <el-option label="Warning" value="Warning"></el-option>
                    <el-option label="Minor" value="Minor"></el-option>
                    <el-option label="Major" value="Major"></el-option>
                    <el-option label="Critical" value="Critical"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="">
                  <button class="ann annbg1" @click.prevent="getWarnList">搜索</button>
                  <!-- <button class="ann annbg2">重置</button> -->
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="flex-1 flex-v p025">
          <div class="table flex-1">
            <el-table
              :data="tableData"
              height="100%"
              v-loading="loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
            >
              <el-table-column prop="id" label="ID"> </el-table-column>
              <el-table-column prop="deviceName" label="设备名称">
              </el-table-column>
              <el-table-column prop="gaugesName" label="表计名称">
              </el-table-column>
              <el-table-column prop="pointName" label="点位"> </el-table-column>
              <el-table-column prop="notifyLevel" label="告警级别">
              </el-table-column>
              <el-table-column prop="content" label="内容描述">
              </el-table-column>
              <el-table-column prop="monitorTime" label="告警开始时间">
              </el-table-column>
              <el-table-column prop="endTime" label="告警结束时间">
              </el-table-column>
              <el-table-column
                prop="endStatus"
                :formatter="formatFreeShippingInfo"
                label="是否恢复"
              >
              </el-table-column>
            </el-table>
          </div>
          <el-pagination
            @current-change="handleCurrentChange"
            v-model:current-page="pageInfo.pageNum"
            layout="prev, pager, next, jumper,total"
            :total="pageInfo.total"
            :page-size="pageInfo.pageSize"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { getBindMeterList } from "@/api/equip";
import { pointList } from "@/api/point";
import { warnList } from "@/api/warn";
import { getEquipList } from "@/utils/common";
export default {
  data() {
    return {
      equiments: [],
      meters: [],
      points: [],
      form: {
        equip: "",
        biaoji: "",
        point: "",
        desc: "",
        level: "",
      },
      loading: false,
      pageInfo: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [],
      currentPage: 1,
    };
  },
  components: {
    Head,
    Sidebar,
    breadInfo,
  },
  created() {
    this.equiments = getEquipList();
  },
  mounted() {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.getWarnList();
    },
    //信息转换为是否
    formatFreeShippingInfo(row, column) {
      return row.endStatus == "1"
        ? "是"
        : row.endStatus == "0"
        ? "否"
        : row.endStatus;
    },
    //获取设备下所有的表
    getAllMeterList() {
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        deviceId: this.form.equip,
      };
      this.meters = [];
      const loading = this.$loading({
        lock: true,
        target: ".meterSelect",
        spinner: "el-icon-loading",
        background: "white",
      });
      getBindMeterList(param).then((res) => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            let t = {};
            t.name = res.data.rows[i].gaugesName;
            t.id = res.data.rows[i].gaugesId;
            this.meters.push(t);
            loading.close();
          }
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //获取设备下的数据点列表
    getPointList() {
      this.points = [];
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        gaugesId: this.form.biaoji,
        deviceId: this.form.equip,
      };
      const loading = this.$loading({
        lock: true,
        target: ".pointSelect",
        spinner: "el-icon-loading",
        background: "white",
      });
      pointList(param).then((res) => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            let t = {};
            t.name = res.data.rows[i].name;
            t.id = res.data.rows[i].id;
            this.points.push(t);
            loading.close();
          }
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //获取告警列表
    getWarnList() {
      this.loading = true;
      let param = {
        content: this.form.desc,
        deviceId: this.form.equip,
        gaugesId: this.form.biaoji,
        notifyLevel: this.form.level,
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        pointId: this.form.point,
        tenantId: JSON.parse(sessionStorage.getItem("user")).tenantId,
      };
      warnList(param).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.pageInfo.total = res.data.total;
          this.tableData = res.data.rows;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped></style>
